/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { When } from "react-if";
import { Box, ModalConfirm, theme } from "@nordcloud/gnui";
import { CloudAccountsQueryVariables } from "~/generated/graphql";
import {
  UniversalWrap,
  TableLoader,
  Pagination,
  ReactTableV2,
} from "~/components";
import { useCurrency, useDisclosure, useQueryState } from "~/hooks";
import {
  generateActionConfirmText,
  isNotEmpty,
  isNotNil,
  Maybe,
  SearchSortOrder,
} from "~/tools";
import { ACTION, initialState, reducer } from "../../accounts/reducer";
import { AccountCredentialsUpdateSidebar } from "../AccountCredentialsUpdateSidebar/AccountCredentialsUpdateSidebar";
import { BillingDataSidebar } from "../BillingData";
import { HybridOnboardSidebar } from "../components";
import {
  useSelectCloudAccounts,
  useChargeTypes,
  useCloudAccountBatchDelete,
} from "../hooks";
import {
  CloudAccount,
  CloudAccountsQueryState,
  CloudAccountsResponse,
  ProviderTab,
} from "../types";
import { AccountListColumns } from "./AccountListColumns/AccountListColumns";
import { AddNewAccountSidebar } from "./AddNewAccountSidebar/AddNewAccountSidebar";
import { CloudAccountFilterBox } from "./CloudAccountFilterBox";
import {
  AccountWarningNotification,
  BillingDataPendingNotification,
} from "./Notifications";
import { SelectedAccountMessage } from "./SelectedAccountsMessage";
import { TotalCostsByChargeTypeBar } from "./TotalCostsByChargeTypeBar";
import { useBillingDataStatus } from "./useBillingDataStatus";

type Props = {
  openFiltersSidebar: () => void;
  searchInput?: Maybe<string>;
  isLoading: boolean;
  data?: Maybe<CloudAccountsResponse>;
  fetchCloudAccountsVariables: CloudAccountsQueryVariables;
  movedToK8s?: number;
  movedToOc?: number;
  onSetSearchInput: (input: string) => void;
  hasWarning: boolean;
};

export function CloudAccountTab({
  isLoading,
  searchInput,
  onSetSearchInput,
  openFiltersSidebar,
  movedToK8s,
  movedToOc,
  data,
  fetchCloudAccountsVariables,
  hasWarning,
}: Props) {
  const {
    open: openBillingDataSidebar,
    close: closeBillingDataSidebar,
    isOpen: isOpenBillingDataSidebar,
  } = useDisclosure();

  const {
    open: openAddNewAccountSidebar,
    close: closeAddNewAccountSidebar,
    isOpen: isAddNewAccountSidebarOpen,
  } = useDisclosure();

  const {
    open: openCredentialsUpdateSteps,
    close: closeCredentialsUpdateSteps,
    isOpen: showCredentialsUpdateSteps,
  } = useDisclosure();

  const [selectedAccountNid, setSelectedAccountNid] = useState("");

  const [onboardAccount, setOnboardAccount] = useState<CloudAccount>();

  const { state, updateQueryState } = useQueryState<CloudAccountsQueryState>();
  const { provider, order, chargeType: queryChargeType, limit } = state;

  const onSort = (sort: { id: string; desc: boolean }) => {
    updateQueryState({
      ...state,
      chargeType: sort.id,
      order: sort.desc ? SearchSortOrder.Dsc : SearchSortOrder.Asc,
      page: 0,
    });
  };

  const defaultSort = {
    id: queryChargeType ?? "Total Cost",
    desc: isNotNil(order) ? order === SearchSortOrder.Dsc : true,
  };

  const {
    handleSelectAccount,
    handleSelectAllAccounts,
    handleSelectPageOfAccounts,
    handleUnselectAllAccounts,
    showClearSelectionButton,
    visibleAccountsNids,
    isPageSelected,
    isAllAccountsSelected,
    selectedAccountsNids,
  } = useSelectCloudAccounts({
    accounts: data?.accounts ?? [],
    provider,
  });

  const { chargeTypes, isLoadingChargeTypes, chargeTypesError } =
    useChargeTypes(provider);

  const [handleDeleteAccounts] = useCloudAccountBatchDelete({
    responseParameters: fetchCloudAccountsVariables,
  });
  const { isBillingDataOnboarding } = useBillingDataStatus(provider);

  const [reducerState, dispatch] = useReducer(reducer, initialState);

  const onRemoveHandler = useCallback((id: string, name: string) => {
    dispatch({ type: ACTION.REMOVE, payload: { id, name } });
  }, []);

  const onUpdateHandler = (accountNid: string) => {
    setSelectedAccountNid(accountNid);
    handleUnselectAllAccounts();
    openCredentialsUpdateSteps();
  };

  const closeUpdateCredentialsSidebar = () => {
    setSelectedAccountNid("");
    closeCredentialsUpdateSteps();
  };

  const { currency } = useCurrency();

  const accountCount = data?.count ?? 0;

  const {
    open: openOnboardSidebar,
    close: closeOnboardSidebar,
    isOpen: isOpenOnboardSidebar,
  } = useDisclosure();

  const onOpenOnboardSidebar = (account: CloudAccount) => {
    setOnboardAccount(account);
    openOnboardSidebar();
  };

  const columns = useMemo(
    () =>
      AccountListColumns({
        onRemoveHandler,
        onUpdateHandler,
        onSelectHandler: (nid) => {
          setSelectedAccountNid("");
          handleSelectAccount(nid);
        },
        onPageSelect: () => {
          setSelectedAccountNid("");
          handleSelectPageOfAccounts();
        },
        openOnboardSidebar: onOpenOnboardSidebar,
        isPageSelected,
        selectedAccountsNids,
        chargeTypes,
        provider,
        currency,
      }),
    [
      chargeTypes,
      onRemoveHandler,
      provider,
      currency,
      handleSelectPageOfAccounts,
      handleSelectAccount,
    ]
  );

  const onDelete = async () => {
    await handleDeleteAccounts([reducerState.tempOrgUnitData.nid ?? ""]);

    closeModal();
  };

  const closeModal = () => {
    dispatch({ type: ACTION.CLOSE_MODAL });
  };

  useEffect(() => {
    handleUnselectAllAccounts();
  }, [limit]);

  return (
    <>
      <UniversalWrap
        errorProps={{ error: chargeTypesError }}
        loaderProps={{
          loading: isLoading || isLoadingChargeTypes,
          Component: Loader,
        }}
      >
        <When condition={isBillingDataOnboarding}>
          <BillingDataPendingNotification />
        </When>
        <When condition={hasWarning}>
          <AccountWarningNotification provider={provider} />
        </When>
        <CloudAccountFilterBox
          fetchCloudAccountsVariables={fetchCloudAccountsVariables}
          searchInput={searchInput}
          openFiltersSidebar={openFiltersSidebar}
          openBillingDataSidebar={openBillingDataSidebar}
          openAddNewAccountSidebar={openAddNewAccountSidebar}
          selectedAccountsNids={selectedAccountsNids}
          openCredentialsSidebar={openCredentialsUpdateSteps}
          onUnselectAllAccounts={handleUnselectAllAccounts}
          onSetSearchInput={onSetSearchInput}
        />
        <When condition={provider !== ProviderTab.VMWARE}>
          <TotalCostsByChargeTypeBar
            movedToK8s={movedToK8s}
            movedToOc={movedToOc}
          />
        </When>
        <When condition={isNotEmpty(selectedAccountsNids)}>
          <Box boxStyle="grey">
            <SelectedAccountMessage
              accountsCount={accountCount}
              showClearSelectionButton={showClearSelectionButton}
              selectedAccounts={selectedAccountsNids}
              isAllAccountsSelected={isAllAccountsSelected}
              visibleAccounts={visibleAccountsNids}
              onSelectAll={handleSelectAllAccounts}
              onUnselectAll={handleUnselectAllAccounts}
            />
          </Box>
        </When>
        <ReactTableV2
          striped
          sort
          columns={columns}
          data={data?.accounts || []}
          defaultSort={defaultSort}
          onSort={onSort}
        />
        <When condition={accountCount > 0}>
          <Pagination count={accountCount} />
        </When>
      </UniversalWrap>
      <ModalConfirm
        isOpen={reducerState.isModalConfirmOpen}
        confirm={onDelete}
        actionLabel="Delete"
        contentLabel="Delete Account"
        onClose={closeModal}
      >
        {generateActionConfirmText(reducerState.tempOrgUnitData.name ?? "")(
          "delete"
        )()}
      </ModalConfirm>
      <BillingDataSidebar
        isOpen={isOpenBillingDataSidebar}
        provider={provider}
        onClose={closeBillingDataSidebar}
      />
      <AddNewAccountSidebar
        isOpen={isAddNewAccountSidebarOpen}
        close={closeAddNewAccountSidebar}
      />
      <When condition={isNotEmpty(selectedAccountsNids) || selectedAccountNid}>
        <AccountCredentialsUpdateSidebar
          provider={provider}
          isOpen={showCredentialsUpdateSteps}
          nids={
            isNotEmpty(selectedAccountsNids)
              ? selectedAccountsNids
              : [selectedAccountNid]
          }
          onClose={closeUpdateCredentialsSidebar}
        />
      </When>
      {isNotNil(onboardAccount) && (
        <HybridOnboardSidebar
          account={onboardAccount}
          isOpen={isOpenOnboardSidebar}
          onClose={closeOnboardSidebar}
        />
      )}
    </>
  );
}

function Loader() {
  return (
    <TableLoader
      gapY={10}
      gapX={6}
      css={{
        marginTop: theme.spacing.spacing08,
      }}
      rows={[
        {
          count: 8,
          height: 60,
          items: [
            {
              widthPercent: 4,
            },
            {
              widthPercent: 4,
            },
            {
              widthPercent: 43,
            },
            {
              widthPercent: 43,
            },
            {
              widthPercent: 6,
            },
          ],
        },
      ]}
    />
  );
}
