/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Cell, Row } from "@tanstack/react-table";
import styled from "styled-components";
import { Button, Checkbox, SVGIcon } from "@nordcloud/gnui";
import { ACTION, IdCell, PopoverControls } from "~/components";
import { AccountStatusIcon } from "~/components/Icons";
import { Currency, isNil, isNotNil } from "~/tools";
import { CloudAccount, ProviderTab } from "../../types";
import {
  getAccountUrl,
  canBeOnboarded,
  isPossibleToUpdateCredentialsV2,
  isKubernetesOrOpenshiftTab,
  getAccountDisplayName,
  hasNoData,
  isVMWare,
  downloadDeployment,
} from "../../utils";
import { getChargeTypeLabel, getProviderIconName } from "../utils";
import { CurrencyCell } from "./CurrencyCell";

const NUMBER_COLUMNS_WIDTH = "9rem";

type Props = {
  chargeTypes: string[];
  provider: ProviderTab;
  currency: Currency;
  selectedAccountsNids: string[];
  isPageSelected: boolean;
  onRemoveHandler: (id: string, name: string) => void;
  onUpdateHandler: (accountNid: string) => void;
  onPageSelect: () => void;
  onSelectHandler: (nid: string) => void;
  openOnboardSidebar: (account: CloudAccount) => void;
};

export function AccountListColumns({
  onRemoveHandler,
  onUpdateHandler,
  onPageSelect,
  onSelectHandler,
  openOnboardSidebar,
  isPageSelected,
  selectedAccountsNids,
  chargeTypes,
  provider,
  currency,
}: Props) {
  const showTotalCosts = provider !== ProviderTab.VMWARE;

  const providerIconColumn = isKubernetesOrOpenshiftTab(provider)
    ? [
        {
          id: "providerIcon",
          meta: {
            width: "1.5rem",
          },
          header: "",
          cell: ({ row: { original: account } }: Cell<CloudAccount>) => {
            if (isNil(account)) {
              return null;
            }

            const { provider: accountProvider, providerType } = account;
            const iconName = getProviderIconName(accountProvider, providerType);

            return isNotNil(iconName) ? (
              <div css={{ position: "relative" }}>
                <SVGIcon name={iconName} />
              </div>
            ) : null;
          },
        },
      ]
    : [];

  const sharedColumns = [
    {
      id: "checkbox",
      meta: { width: "1rem" },
      header: <Checkbox checked={isPageSelected} onChange={onPageSelect} />,
      cell: ({ row: { original: account } }: Cell<CloudAccount>) => {
        const { nid } = account ?? {};

        return isNotNil(nid) ? (
          <Checkbox
            checked={selectedAccountsNids.includes(nid)}
            onChange={() => onSelectHandler(nid)}
          />
        ) : null;
      },
    },
    {
      id: "statusIcon",
      header: "",
      meta: { width: "1.5rem" },
      cell: ({ row: { original: account } }: Cell<CloudAccount>) => {
        const { cloudStatus } = account ?? {};
        return isNotNil(cloudStatus) ? (
          <div css={{ position: "relative" }}>
            <AccountStatusIcon status={cloudStatus} />
          </div>
        ) : null;
      },
    },
    ...providerIconColumn,
    {
      accessorKey: "Name",
      id: "Name",
      header: <div css={{ minWidth: "10rem" }}>Account Name / Account ID</div>,
      cell: ({ row: { original } }: Cell<CloudAccount>) => {
        return isNotNil(original) ? (
          <IdCell
            type="cloud-accounts"
            id={original.nid}
            name={getAccountDisplayName(original)}
            provider={original.provider}
            providerId={original.providerId}
            description={original.description ?? ""}
            url={getAccountUrl(original.providerId, original.provider)}
          />
        ) : null;
      },
    },
  ];

  const totalCostColumn = showTotalCosts
    ? [
        {
          id: "Total Cost",
          accessorKey: "Total Cost",
          header: <HeaderWrapper>Total Cost</HeaderWrapper>,
          meta: { width: NUMBER_COLUMNS_WIDTH },
          cell: getTotalCostCell(currency),
        },
      ]
    : [];

  const actionColumn = {
    header: "",
    id: "actions",
    meta: { width: "2rem" },
    cell: ({ row }: Cell<CloudAccount>) => {
      const actionItems = getActionItems({
        row,
        onRemoveHandler,
        onUpdateHandler,
        openOnboardSidebar,
      });

      return isNotNil(row.original) ? (
        <>
          <PopoverControls
            items={actionItems}
            trigger={<Button severity="low" icon="menu" size="md" />}
          />
        </>
      ) : null;
    },
  };

  const generatedColumns = !isKubernetesOrOpenshiftTab(provider)
    ? chargeTypes.map((columnName) => ({
        id: columnName,
        accessorKey: columnName,
        header: <HeaderWrapper>{getChargeTypeLabel(columnName)}</HeaderWrapper>,
        meta: { width: NUMBER_COLUMNS_WIDTH },
        cell: ({ row: { original: account } }: Cell<CloudAccount>) => {
          const { chargeTypeCosts } = account ?? {};
          const cost = chargeTypeCosts?.find(
            (chargeType) => chargeType.chargeType === columnName
          )?.cost;

          return (
            <div css={{ textAlign: "right" }}>
              {parseFloat(cost ?? "0") !== 0 ? (
                <CurrencyCell value={cost} currency={currency} />
              ) : (
                "-"
              )}
            </div>
          );
        },
      }))
    : [];

  return [
    ...sharedColumns,
    ...generatedColumns,
    ...totalCostColumn,
    actionColumn,
  ];
}

const getTotalCostCell = (currency: Currency) => {
  return ({ row }: Cell<CloudAccount>) => {
    if (
      row.original?.chargeTypeCosts?.some((chargeType) =>
        isNotNil(chargeType.cost)
      )
    ) {
      const totalCost = row.original.chargeTypeCosts
        .map((chargeType) => parseFloat(chargeType.cost))
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      return (
        <div css={{ textAlign: "right" }}>
          <CurrencyCell value={totalCost} currency={currency} />
        </div>
      );
    }
    return (
      <div css={{ textAlign: "right" }}>
        <CurrencyCell currency={currency} />
      </div>
    );
  };
};

type ActionItemsProps = {
  row: Row<CloudAccount>;
  onRemoveHandler: (id: string, name: string) => void;
  onUpdateHandler: (nid: string) => void;
  openOnboardSidebar: (account: CloudAccount) => void;
};

const getActionItems = ({
  row,
  onRemoveHandler,
  onUpdateHandler,
  openOnboardSidebar,
}: ActionItemsProps) => {
  const deleteAction = {
    label: "Delete Account",
    action: ACTION.DELETE,
    onClick: () => {
      if (isNotNil(row.original)) {
        onRemoveHandler(row.original.id, row.original.name);
      }
    },
  };

  const actions = [deleteAction];

  const { provider, providerType, cloudStatus } = row?.original ?? {};

  if (canBeOnboarded(providerType, cloudStatus)) {
    const onboardAction = {
      label: "Onboard Account",
      action: ACTION.EDIT,
      onClick: () => {
        if (isNotNil(row.original)) {
          openOnboardSidebar(row.original);
        }
      },
    };

    actions.unshift(onboardAction);
  }

  if (hasNoData(providerType, cloudStatus)) {
    const buttonText = isVMWare(providerType)
      ? "Download Config"
      : "Download YAML";

    const download = {
      label: buttonText,
      action: ACTION.CUSTOM_TRIGGER,
      customIcon: "download",
      onClick: () => {
        if (isNotNil(row.original)) {
          downloadDeployment(row?.original);
        }
      },
    };

    actions.unshift(download);
  }

  if (isPossibleToUpdateCredentialsV2(providerType, provider)) {
    const updateCredentialsAction = {
      label: "Update Credentials",
      action: ACTION.CUSTOM_TRIGGER,
      customIcon: "settings",
      onClick: () => {
        if (isNotNil(row.original)) {
          onUpdateHandler(row?.original.nid);
        }
      },
    };

    actions.unshift(updateCredentialsAction);
  }

  return actions;
};

const HeaderWrapper = styled.div`
  max-width: ${NUMBER_COLUMNS_WIDTH};
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
